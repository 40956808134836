<template>
  <div class="modal fade" id="forgotPasswordModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" style="border-radius: 0">
        <div class="modal-header border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <h4 class="text-center">Forgot Password</h4>
        <form method="post" action="" style="padding: 30px 40px 40px;">
          <div class="alert alert-danger" role="alert" id="forgot-password-error" v-if="forgot_password_error">{{ forgot_password_error }}</div>
          <div class="form-group">
            <input type="text" v-model="forgot_password_form.email" id="reset-pw-email-input" class="form-control" placeholder="Enter your email" :disabled="loading" />
          </div>
          <div>
            <button type="button" id="reset-pw-button" class="action-button" style="width: 100%; padding: 10px 0 10px 0; margin-top: 20px;" @click="resetPassword();" :disabled="loading">Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="loginModal">
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 0">
        <div class="modal-header border-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <h4 class="text-center" style="padding: 0 32px;">Welcome to Mane Street Market</h4>
        <ul class="nav nav-tabs my-tabs" id="myTab" role="tablist" style="align-self: center; margin-top: 16px;">
          <input type="hidden" name="redirect" value="" />
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="sign-in-tab" data-toggle="tab" href="#sign-in-content" role="tab">Sign In</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="create-account-tab" data-toggle="tab" href="#create-account-content" role="tab">Create Account for Free</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="sign-in-content" role="tabpanel">
            <form method="post" action="" style="padding: 40px;">
              <div class="alert alert-danger" role="alert" id="login-error" v-if="login_error">{{login_error}}</div>
              <div class="form-group">
                <input type="text" v-model="login_form.email" id="email-input" class="form-control" placeholder="Enter your email" :disabled="loading" />
              </div>
              <div class="form-group">
                <input :type="show_password ? 'text' : 'password'" v-model="login_form.password" id="password-input" class="form-control" placeholder="Enter your password" :disabled="loading" @keyup.enter="login" />
                <button @click="e => toggleShowPassword(e)" id="show-password-btn">
                  <EyeOutlined v-if="show_password" />
                  <EyeInvisibleOutlined v-if="!show_password" />
                </button>
              </div>
              <div class="d-flex justify-content-start">
                <div>
                  <label class="form-check-label">
                    <input type="checkbox" v-model="login_form.remember_me" :disabled="loading" /> Remember me
                  </label>
                </div>
                <div class="ml-auto">
                  <a @click="showResetPasswordModal()" style="color: #050930; cursor: pointer;">Forgot password?</a>
                </div>
              </div>
              <div>
                <button type="button" id="login-button" class="action-button" style="width: 100%; padding: 10px 0 10px 0; margin-top: 20px;" @click="login();" :disabled="loading">Sign In</button>
              </div>
              <!-- <div class="separator" style="margin-top: 30px; margin-bottom: 30px;">or</div>
              <div>
                <button type="button" class="btn btn-outline-dark clearfix text-center" style="width: 100%; margin-bottom: 10px;" @click="continueWithApple"><img src="../assets/img/icon_apple.png" height="18" class="float-left" style="margin-top: 3px;" /> Continue with Apple</button>
                <button type="button" class="btn btn-outline-dark clearfix text-center" style="width: 100%; margin-bottom: 10px;" @click="continueWithFacebook"><img src="../assets/img/icon_facebook.png" height="18" class="float-left" style="margin-top: 3px;" /> Continue with Facebook</button>
                <button type="button" class="btn btn-outline-dark clearfix text-center" style="width: 100%; margin-bottom: 10px;" @click="continueWithGoogle"><img src="../assets/img/icon_google.png" height="18" class="float-left" style="margin-top: 3px;" /> Continue with Google</button>
              </div> -->
            </form>
          </div>
          <div class="tab-pane fade" id="create-account-content" role="tabpanel">
            <form method="post" action="" style="padding: 40px;" id="register-form">
              <div class="alert alert-danger" role="alert" id="register-error" v-if="registration_error">{{registration_error}}</div>
              <div class="form-group">
                <input type="text" v-model="registration_form.first_name" class="form-control" placeholder="Enter your first name" :disabled="loading" />
              </div>
              <div class="form-group">
                <input type="text" v-model="registration_form.last_name" class="form-control" placeholder="Enter your last name" :disabled="loading" />
              </div>
              <div class="form-group">
                <input type="text" v-model="registration_form.email" class="form-control" placeholder="Enter your email" :disabled="loading" />
              </div>
              <div class="form-group" style="display: flex;">
                <select class="form-control" v-model="registration_form.phone_prefix" style="width: 100px;">
                  <option value="+1">+1</option>
                  <option value="+31">+31</option>
                  <option value="+32">+32</option>
                  <option value="+33">+33</option>
                  <option value="+34">+34</option>
                  <option value="+36">+36</option>
                  <option value="+44">+44</option>
                  <option value="+49">+49</option>
                  <option value="+351">+351</option>
                  <option value="+353">+353</option>
                </select>
                <input type="text" v-model="registration_form.phone_number" class="form-control" placeholder="Enter your phone number" :disabled="loading" />
              </div>
              <div class="form-group">
                <input type="password" v-model="registration_form.password" class="form-control" placeholder="Enter your password" :disabled="loading" />
              </div>
              <div class="form-group">
                <input type="password" v-model="registration_form.password_repeat" class="form-control" placeholder="Reenter your password" :disabled="loading" />
              </div>
              <div class="form-group">
                <select v-model="registration_form.source" class="form-control" :disabled="loading">
                  <option selected disabled :value="null">How did you hear about Mane Street Market?</option>
                  <option value="social">Social Media</option>
                  <option value="search">Google Search</option>
                  <option value="referral">Referral from a friend</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="form-group" v-if="registration_form.source === 'referral' || registration_form.source === 'other'">
                <input type="text" v-model="registration_form.source_info" class="form-control" :placeholder="registration_form.source === 'referral' ? 'Enter name of referral/friend' : 'Where did you hear about us?'" :disabled="loading" />
              </div>
              <div>
                <label class="form-check-label">
                  <input type="checkbox" v-model="registration_form.accept" value="1" :disabled="loading" /> By creating an account, I accept the <router-link to="/terms-of-use" target="_blank">Terms of Service</router-link> and <router-link to="/privacy-policy" target="_blank">Privacy Policy</router-link>
                </label>
              </div>
              <div>
                <button type="button" class="action-button" style="width: 100%; padding: 10px 0 10px 0; margin-top: 20px;" id="register-button" @click="createAccount();" :disabled="loading">Submit</button>
              </div>
              <!-- <div class="separator" style="margin-top: 30px; margin-bottom: 30px;">or</div>
              <div>
                <button type="button" class="btn btn-outline-dark clearfix text-center" style="width: 100%; margin-bottom: 10px;" @click="continueWithApple"><img src="../assets/img/icon_apple.png" height="18" class="float-left" style="margin-top: 3px;" /> Continue with Apple</button>
                <button type="button" class="btn btn-outline-dark clearfix text-center" style="width: 100%; margin-bottom: 10px;" @click="continueWithFacebook"><img src="../assets/img/icon_facebook.png" height="18" class="float-left" style="margin-top: 3px;" /> Continue with Facebook</button>
                <button type="button" class="btn btn-outline-dark clearfix text-center" style="width: 100%; margin-bottom: 10px;" @click="continueWithGoogle"><img src="../assets/img/icon_google.png" height="18" class="float-left" style="margin-top: 3px;" /> Continue with Google</button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import 'bootstrap/js/src/tab';
import {auth, firebase} from '../firebase';
import Swal from 'sweetalert2';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    EyeOutlined,
    EyeInvisibleOutlined
  },
  data() {
    return {
      show_password: false,
      loading: false,
      login_error: null,
      login_form: {
        email: null,
        password: null,
        remember_me: false
      },
      forgot_password_error: null,
      forgot_password_form: {
        email: null
      },
      registration_error: null,
      registration_form: {
        first_name: null,
        last_name: null,
        email: null,
        phone_prefix: '+1',
        phone_number: null,
        password: null,
        password_repeat: null,
        source: null,
        source_info: null,
        accept: 0,
      }
    }
  },
  watch: {
    'registration_form.phone_number'(newValue) {
      if (newValue != null) {
        newValue = newValue.replace( /[^\d]/g, '' ).trim()
        this.registration_form.phone_number = newValue;
      }
    },
    'registration_form.email'(newValue) {
      this.registration_form.email = newValue?.replace(' ', '');
    },
    'login_form.email'(newValue) {
      this.login_form.email = newValue?.replace(' ', '');
    }
  },
  mounted() {
    // overlay hierarchy for multiple Bootstrap modals
    $(document).on('show.bs.modal', '.modal', function() {
      var zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(function() {
          $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    });

    let rememberedUser = this.$store.state.remembered_user;
    if (rememberedUser) {
      this.login_form.remember_me = true;
      this.login_form.email = rememberedUser;
    }
  },
  methods: {
    async login () {
      this.loading = true;
      this.login_error = null;
      try {
        await auth.signInWithEmailAndPassword(this.login_form.email, this.login_form.password);
        if (this.login_form.remember_me) {
          this.$store.dispatch('rememberUser', this.login_form.email);
        } else {
          this.$store.dispatch('rememberUser', null);
          this.login_form.email = null;
        }
        this.login_form.password = null;
        let redirect = $('#myTab input[name=redirect]').val();
        $('#myTab input[name=redirect]').val('');
        $('#loginModal').modal('hide');
        if (redirect != '') {
          this.$router.push({path: redirect});
        }
        this.$emit('login-success');
      } catch (error) {
        this.login_error = 'Wrong email or password!';
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async continueWithGoogle() {
      this.loading = true;
      try {
        let redirect = $('#myTab input[name=redirect]').val();
        let provider = new firebase.auth.GoogleAuthProvider();
        await auth.signInWithPopup(provider);
        $('#myTab input[name=redirect]').val('');
        $('#loginModal').modal('hide');
        if (redirect != '') {
          this.$router.push({path: redirect});
        }
        this.$emit('login-success');
      } catch (error) {
        Swal.fire({
          title: 'Error signing in',
          text: error.message
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async continueWithFacebook() {
      this.loading = true;
      try {
        let redirect = $('#myTab input[name=redirect]').val();
        let provider = new firebase.auth.FacebookAuthProvider();
        await auth.signInWithPopup(provider);
        $('#myTab input[name=redirect]').val('');
        $('#loginModal').modal('hide');
        if (redirect != '') {
          this.$router.push({path: redirect});
        }
        this.$emit('login-success');
      } catch (error) {
        Swal.fire({
          title: 'Error signing in',
          text: error.message
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async continueWithApple() {
      this.loading = true;
      try {
        let redirect = $('#myTab input[name=redirect]').val();
        let provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        await auth.signInWithPopup(provider);
        $('#myTab input[name=redirect]').val('');
        $('#loginModal').modal('hide');
        if (redirect != '') {
          this.$router.push({path: redirect});
        }
        this.$emit('login-success');
      } catch (error) {
        Swal.fire({
          title: 'Error signing in',
          text: error.message
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async createAccount() {
      this.registration_error = null;
      if (!this.registration_form.accept) {
        this.registration_error = 'Please accept Terms of Service and Privacy Policy!';
        return;
      }
      if (!this.registration_form.source) {
        this.registration_error = 'Please let us know how you heard about us';
        return;
      }
      if ((this.registration_form.source === 'referral' || this.registration_form.source === 'other') && !this.registration_form.source_info?.trim()?.length) {
        this.registration_error = 'Please let us know how you heard about us';
        return;
      }
      this.loading = true;
      try {
        const { phone_prefix, phone_number, ...formWithoutPhone } = this.registration_form;
        await axios.post('/api/auth/register', {
          ...formWithoutPhone,
          phone_number: phone_prefix + phone_number
        });
        await auth.signInWithEmailAndPassword(this.registration_form.email, this.registration_form.password);
        this.registration_error = null;
        this.registration_form.email = null;
        this.registration_form.password = null;
        this.registration_form.password_repeat = null;
        this.registration_form.first_name = null;
        this.registration_form.last_name = null;
        this.registration_form.phone_prefix = '+1';
        this.registration_form.phone_number = null;
        this.registration_form.source = null;
        this.registration_form.source_info = null;
        this.registration_form.accept = null;
        $('#myTab input[name=redirect]').val('');
        $('#loginModal').modal('hide');
        // 2023-10-18: Removed as FB is flagging website as a violation of their policy for GPS
        // fbq('track', 'CompleteRegistration');
        this.$emit('login-success');
      } catch (error) {
        if (error.response
          && error.response.data
          && error.response.data.error) {
          this.registration_error = error.response.data.error.message;
        } else {
          this.registration_error = 'An error occurred. Please try again!';
        }
      } finally {
        this.loading = false;
      }
    },
    showResetPasswordModal() {
      $('#forgotPasswordModal').modal('show');
    },
    async resetPassword() {
      if (this.forgot_password_form.email == null) {
        this.forgot_password_error = 'Please fill in a valid email address.';
        return;
      }
      this.loading = true;
      this.forgot_password_error = null;
      await auth.sendPasswordResetEmail(this.forgot_password_form.email, {
        url: `${ process.env.VUE_APP_WEB_URL }/`
      }).then(() => {
        Swal.fire({
          title: 'Reset Sent',
          text: 'Please check your email to reset password.'
        });
        this.forgot_password_form.email = null;
        $('#forgotPasswordModal').modal('hide');
        $('#loginModal').modal('hide');
      }).catch((e) => {
        if (e.message != null) {
          Swal.fire({
            title: 'Error',
            text: e.message
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Error resetting password. Please try again.'
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.show_password = !this.show_password;
    }
  }
}
</script>

<style lang="scss" scoped>
  #show-password-btn {
    background: none;
    outline: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    display: flex;
    align-items: center;
    opacity: 0.6;
    padding-right: 12px;

    &:hover {
      opacity: 0.4;
    }
  }

  .form-group {
    position: relative;
  }
</style>